// Inject USWDS's javascript. This script needs to come at the end of <body>,
// since USWDS's JS assumes the DOM is already setup and available.
import 'uswds';
import '@/font-awesome-icons';
import '@/auto-icons';
import '@/leaving-site';
import '@hotwired/turbo-rails';
import '../controllers';

import domready from 'domready';

// Remove the "usa-js-loading" CSS class after loading the USWDS JS (this
// prevents flickers on load). This is CSS class is originally added to all
// pages in src/app.ts.
//
// Normally this is done via the uswds-init.js file loaded in <head>, but
// since it's difficult to inject such scripts in this Vue/Vite setup,
// we'll manually perform these same steps. See:
// https://github.com/uswds/uswds/blob/v2.13.1/src/js/uswds-init.js
domready(() => {
  document.documentElement.classList.remove('usa-js-loading');
});
