import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['funding-map'];

  connect() {
    document.addEventListener('turbo:morph', () => {
      this.fundingMapOutlet.drawMap();
    });
  }
}
