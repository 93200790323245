import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  static outlets = ['form'];

  toggle(event) {
    this.itemTargets.forEach((item) => {
      const checkbox = item;
      checkbox.checked = event.target.checked;
    });
    this.formOutlet.submit();
  }
}
