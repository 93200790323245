import { Controller } from '@hotwired/stimulus';
import Highcharts from 'highcharts';
import highchartsMap from 'highcharts/modules/map';
import highchartsAccessibilityInit from 'highcharts/modules/accessibility';
import usStates from '@highcharts/map-collection/countries/us/custom/us-all-territories.topo.json';

export default class extends Controller {
  static targets = ['map'];

  static values = {
    awards: Array,
    states: Array,
  };

  connect() {
    this.drawMap();
  }

  drawMap() {
    highchartsMap(Highcharts);
    highchartsAccessibilityInit(Highcharts);

    Highcharts.mapChart(this.mapTarget, this.mapOptions);
  }

  get mapOptions() {
    // formatting state data to match highcharts state data
    // for join and gives all states included the format with the number 2 i.e. [us-ma, 2]
    // All other states are given the same format but the number 0 - every state needs a number
    // in order to appear on the map -- this filtering process is how we keep the us territories
    // other than PR off of the map -- FYI PR has a slightly different code: pr-3614

    const allStatesButPR = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
    const stateArray = [];
    allStatesButPR.forEach((state) => {
      if (this.statesValue.includes(state)) {
        stateArray.push([`us-${state.toLowerCase()}`, 2]);
      } else {
        stateArray.push([`us-${state.toLowerCase()}`, 0]);
      }
    });

    if (this.statesValue.includes('PR')) {
      stateArray.push(['pr-3614', 2]);
    } else {
      stateArray.push(['pr-3614', 0]);
    }

    // temporary join of cities to latitudes and longitudes by id until geography in database
    const joinGeography = {
      16: [40.440130, -79.997754],
      9: [34.500000, -118.245617],
      12: [40.714368, -74.500000],
      19: [42.360927, -71.056126],
      8: [37.773955, -122.416660],
      27: [33.212773, -87.564301],
      13: [58.302336, -134.423802],
      6: [45.513733, -122.675682],
      4: [40.000000, -74.500000],
      22: [41.502042, -81.693516],
      11: [40.015117, -105.271834],
      20: [44.5000000, -93.000000],
      21: [34.500000, -119.000000],
      25: [47.607670, -122.326762],
      5: [40.678277, -73.942435],
      10: [38.000000, -122.000000],
      23: [34.000000, -118.000000],
      26: [42.000000, -71.000000],
      24: [40.000000, -74.000000],
      14: [43.660434, -70.263024],
      28: [40.759640, -111.887300],
      7: [34.058728, -117.275829],
      15: [41.879621, -87.639880],
      18: [33.350000, -117.500000],
      17: [44.977532, -93.269845],
    };
    const mapDataWithGeo = this.awardsValue.map((award) => [...award, ...joinGeography[award[0]]]);
    // TODO - when the latitutude and longitude are added in the database,
    // the mapDataWithGeo variable should be an array of values
    // there are currently 7 values as outlined in the chart object
    // under the 'keys' array --  if additional values are added to the mapDataWithGeo array
    // or if values are in an order different than the keys array then
    // the keys array needs to be changed to reflect that change

    return {
      chart: {
        map: usStates,
        height: 500,
        backgroundColor: '',
        animation: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      colorAxis: {
        min: 0,
        dataClasses:
        [
          {
            to: 1,
            color: '#dcdcdc',
          },
          {
            from: 1,
            color: '#00b5ef',
          },
        ],
      },
      series: [{
        data: stateArray,
        name: '',
        nullColor: '#dcdcdc',
        showInLegend: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        allAreas: false,
        enableMouseTracking: false,
      },
      {
        allowPointSelect: true,
        cursor: 'pointer',
        stickyTracking: false,
        type: 'mappoint',
        name: 'city',
        color: '#f57f29',
        marker: {
          lineWidth: 1,
          lineColor: '#fff',
          symbol: 'circle',
          radius: 8,
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        keys: ['id', 'awardee', 'city', 'stateAbb', 'locations', 'topic', 'lat', 'lon'],
        data: mapDataWithGeo,
        events: {
          click(e) {
            const anchor = `#award-${e.target.point.id}`;
            Turbo.visit(anchor); // eslint-disable-line no-undef
          },
        },
      }],
      tooltip: {
        useHTML: true,
        padding: 15,
        formatter: (t) => {
          const awardeeName = `<h4>${t.chart.hoverPoint.awardee}</h4>`;
          const awardeeLocationAndTopic = `${t.chart.hoverPoint.city}, ${t.chart.hoverPoint.stateAbb}<br>Topic ${t.chart.hoverPoint.topic}`;
          const workLocations = `<h5 class="work-locations margin-top-2">Work Locations</h5>${t.chart.hoverPoint.locations}`;
          return awardeeName + awardeeLocationAndTopic + workLocations;
        },
      },
    };
  }
}
