import { Controller } from '@hotwired/stimulus';

function disableExtraSortByField() {
  const elements = document.getElementsByName('sort_by');

  elements.forEach((element) => {
    const sortByField = element;
    if (!sortByField.checkVisibility()) {
      sortByField.disabled = 'disabled';
    }
  });
}

export default class extends Controller {
  static classes = ['loading'];

  static targets = ['resetScroll'];

  connect() {
    let resetScroll = false;
    this.element.addEventListener('turbo:before-fetch-request', (event) => {
      if (this.resetScrollTargets.includes(event.target)) {
        resetScroll = true;
      } else {
        this.element.classList.add(this.loadingClass);
      }
    });

    document.addEventListener('turbo:morph', () => {
      if (resetScroll) {
        const metaTurboRefreshScroll = document.querySelector('meta[name="turbo-refresh-scroll"]');
        metaTurboRefreshScroll.content = 'reset';
        resetScroll = false;
      }
    });
  }

  submit() {
    disableExtraSortByField();
    this.element.requestSubmit();
  }
}
